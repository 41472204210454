import { XIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { sectionStyle } from 'holded/lib/styles';
import { getTopBarOptions } from 'holded/modules/cms/application/page';
import { Banner1Section } from 'holded/modules/cms/domain/components/banner';

import styles from './banner.module.scss';
import { CountdownTimer } from './CountdownTimer';
import HeroIcon from '../../shared/components/HeroIcon';
import { useCounterStartDate } from '../../shared/hooks/useCounterStartDate';
const classNames = (...classes: string[]) => classes.join(' ');

const BUTTON_BG_THEME: Record<Banner1Section['theme'], string> = {
  gradient: 'bg-blue-800',
  gradient2: 'bg-[#140639]',
  dark: 'bg-[#2d3f5f]',
};

const Banner1 = ({
  title,
  theme,
  closeOption,
  button,
  options,
  contactLink,
  contactTitle,
  counterDays,
  daysLabel,
  dayLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
}: Banner1Section) => {
  const router = useRouter();
  const { counterDate, hideCountdown, countdownIsHidden } = useCounterStartDate(counterDays);
  const [show, setShow] = useState(true);
  const shouldShowCounter = !!counterDays && counterDays > 0;
  const showTopBar = getTopBarOptions({ options, router });
  const contanctButtonBg = BUTTON_BG_THEME[theme];

  const handlerBanner = () => {
    setShow(false);
  };

  if (!show || !showTopBar) {
    return null;
  }

  return (
    <div
      className={classNames(
        'relative top-0 z-10 w-full',
        theme.startsWith('gradient') ? styles[theme] : sectionStyle.background.dark,
      )}
    >
      <div className="max-w-navbar mx-auto px-2.5 py-2.5 flex flex-row lg:flex-col justify-center lg:py-0 lg:h-[67px]">
        <div className="flex flex-col lg:flex-row items-center md:items-center justify-center md:justify-between gap-1.5 lg:gap-5 sm:text-center sm:px-0">
          <p className={'text-b3 leading-6 text-white flex'}>
            <span className={`hidden lg:flex`}>{title}</span>
            {button && (
              <span className="block sm:ml-2 sm:inline-block">
                <a
                  href={button?.url ?? ''}
                  className={`${sectionStyle.textColor.light} text-b3 leading-6 font-semibold underline hover:opacity-80 flex`}
                >
                  <span className="hidden lg:block">{button?.text}</span>
                  <span className={`lg:hidden ${styles.capitalize}`}>{button?.text}</span>
                  <span aria-hidden="true">&nbsp;&rarr;</span>
                </a>
              </span>
            )}
          </p>
          {shouldShowCounter && !countdownIsHidden && (
            <CountdownTimer
              endDate={counterDate}
              daysLabel={daysLabel}
              dayLabel={dayLabel}
              hoursLabel={hoursLabel}
              minutesLabel={minutesLabel}
              secondsLabel={secondsLabel}
              onHideCounter={hideCountdown}
              hideEndCounter
            />
          )}
          {contactLink && (
            <p className={'text-b3 leading-6 text-white inline-flex justify-center items-center'}>
              <span className="hidden lg:block">{contactTitle}</span>
              <a
                href={contactLink.href}
                className={`${contanctButtonBg} px-[18px] py-2 rounded-3xl inline-flex justify-center items-center sm:ml-2 hover:opacity-80`}
              >
                {contactLink.icon && <HeroIcon icon={contactLink.icon} size={4} outline extraClass="mr-2" />}
                {contactLink.name}
              </a>
            </p>
          )}
        </div>
        {closeOption && (
          <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              className="flex p-2 rounded-md hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className={'text-white h-6 w-6'} aria-hidden="true" onClick={handlerBanner} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner1;
